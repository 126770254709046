import axios from '@/libs/axios'

export default {
  getMyAvailabilities: month => axios.get(`/schedule/availability/my/${month.format('YYYY-MM')}`),
  getAvailabilitiesForMonth: month => axios.get(`/schedule/availability/${month.format('YYYY-MM')}`),
  getAvailabilitiesByQuery: query => axios.get('/schedule/availability', { params: query }),
  addAvailabilities: data => axios.post('/schedule/availability', data),
  deleteAvailabilities: ids => axios.delete('/schedule/availability', { data: ids }),

  getStaffingNeeds: (dateFrom, dateTo) => axios.get(`/schedule/staffing-needs/${dateFrom}/${dateTo}`),
  updateStaffingNeeds: payload => axios.post('/schedule/staffing-needs', payload),
  getShiftsFulfillment: month => axios.get(`/schedule/staffing-needs/shift-fulfillment/${month.format('YYYY-MM')}`),

  getMySchedule: month => axios.get(`/schedule/shift/my/${month.format('YYYY-MM')}`),
  addShifts: payload => axios.post('/schedule/shift', payload),
  deleteShift: id => axios.delete(`/schedule/shift/${id}`),
  generateSchedule: (dateFrom, dateTo, query) => axios.post(
    `/schedule/shift/generate/${dateFrom}/${dateTo}`,
    {},
    { params: query },
  ),
}
