/*= ========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */
const defaultUser = {
  id: '',
  email: '',
  username: '',
  name: '',
  surname: '',
  position: '',
  roles: [],
  teamLoginOnly: false,
  requirePasswordChange: false,
}

const defaultUserTeam = {
  id: '',
  status: true,
  currentOrder: {},
  teamName: {
    id: '',
    name: '',
  },
  vehicle: {
    id: '',
    brand: '',
    model: '',
    registrationNumber: '',
    sideNumber: '',
    status: '',
    fuelCostPerOneKm: 0,
    additionalCostsDaily: 0,
  },
  driverUser: {
    id: '',
    name: '',
    surname: '',
    position: '',
  },
  paramedicUser: {
    id: '',
    name: '',
    surname: '',
    position: '',
  },
  teamStartedAt: '',
  checklistConfirmedAt: null,
}

const userInfoFromLocalStorage = JSON.parse(localStorage.getItem('userInfo')) || { roles: [], rawRoles: [] }
const getUserTokens = () => JSON.parse(localStorage.getItem('tokens')) || {}

const getUserInfo = () => {
  const userInfo = {}

  Object.keys(defaultUser).forEach(key => {
    userInfo[key] = userInfoFromLocalStorage[key] ? userInfoFromLocalStorage[key] : defaultUser[key]
  })

  Object.keys(userInfoFromLocalStorage).forEach(key => {
    if (userInfo[key] === undefined && userInfoFromLocalStorage[key] !== null) {
      userInfo[key] = userInfoFromLocalStorage[key]
    }
  })

  return userInfo
}

export default {
  twoFactorAuthenticationInProgress: false,
  activeUser: getUserInfo(),
  activeUserTokens: getUserTokens(),
  userTeam: defaultUserTeam,
  defaultUserTeam,
  showInitTeamPopup: false,
  teamLoaded: false,
  notifications: [],
  isUserLoggedIn: () => {
    let isAuthenticated = true
    if (!localStorage.getItem('userInfo')) {
      isAuthenticated = false
    }
    return isAuthenticated
  },
}
