export default {
  SET_MY_AVAILABILITIES(state, availabilities) {
    state.myAvailabilities = availabilities
  },
  SET_AVAILABILITIES(state, availabilities) {
    state.availabilities = availabilities
  },

  SET_MY_SCHEDULE(state, schedule) {
    state.mySchedules = schedule
  },
}
