import _mergeJSXProps from 'babel-helper-vue-jsx-merge-props'

const MagicWandIcon = {
  name: 'MagicWandIcon',
  props: {
    size: {
      type: String,
      default: '24',
      validator: function validator(s) {
        return !Number.isNaN(Number(s)) || (s.length >= 2 && !Number.isNaN(Number(s.slice(0, s.length - 1))) && s.slice(-1) === 'x')
      },
    },
  },
  functional: true,
  render: function render(h, ctxParam) {
    const ctx = ctxParam
    const size = ctx.props.size.slice(-1) === 'x' ? `${ctx.props.size.slice(0, ctx.props.size.length - 1)}em` : `${parseInt(ctx.props.size, 10)}px`
    const attrs = ctx.data.attrs || {}
    attrs.width = attrs.width || size
    attrs.height = attrs.height || size
    ctx.data.attrs = attrs
    return h('svg', _mergeJSXProps([{
      attrs: {
        xmlns: 'http://www.w3.org/2000/svg',
        width: '24',
        height: '24',
        viewBox: '0 0 535.5 535.5',
        fill: 'currentColor',
        stroke: 'currentColor',
        'stroke-width': '2',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
      class: 'feather feather-magic-wand',
    }, ctx.data]), [
      h('polygon', { attrs: { points: '165.75,91.8 229.5,127.5 193.799,63.75 229.5,0 165.75,35.7 102,0 137.7,63.75 102,127.5' } }),
      h('polygon', { attrs: { points: '471.75,341.7 408,306 443.7,369.75 408,433.5 471.75,397.8 535.5,433.5 499.8,369.75 535.5,306' } }),
      h('polygon', { attrs: { points: '535.5,0 471.75,35.7 408,0 443.7,63.75 408,127.5 471.75,91.8 535.5,127.5 499.8,63.75' } }),
      h('path', { attrs: { d: 'M341.7,135.15c-10.2-10.2-25.5-10.2-35.7,0L7.649,433.5c-10.2,10.2-10.2,25.5,0,35.7l58.65,58.649 c10.2,10.2,25.5,10.2,35.7,0L400.35,229.5c10.2-10.2,10.2-25.5,0-35.7L341.7,135.15z M313.65,275.4L260.1,221.85l61.2-61.2 l53.55,53.55L313.65,275.4z' } }),
    ])
  },
}

// eslint-disable-next-line import/prefer-default-export
export { MagicWandIcon }
