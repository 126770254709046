import scheduleRequests from '../../http/requests/schedule/schedule'

export default {
  fetchMyAvailabilities: ({ commit }, month) => new Promise((resolve, reject) => {
    scheduleRequests.getMyAvailabilities(month)
      .then(response => {
        commit('SET_MY_AVAILABILITIES', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchAvailabilitiesForMonth: ({ commit }, month) => new Promise((resolve, reject) => {
    scheduleRequests.getAvailabilitiesForMonth(month)
      .then(response => {
        commit('SET_AVAILABILITIES', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchAvailabilitiesByQuery: (_, payload) => new Promise((resolve, reject) => {
    scheduleRequests.getAvailabilitiesByQuery(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addAvailabilities: (_, payload) => new Promise((resolve, reject) => {
    scheduleRequests.addAvailabilities(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  deleteAvailabilities: (_, ids) => new Promise((resolve, reject) => {
    scheduleRequests.deleteAvailabilities(ids)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  fetchStaffingNeeds: (_, payload) => new Promise((resolve, reject) => {
    scheduleRequests.getStaffingNeeds(payload.dateFrom, payload.dateTo)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  updateStaffingNeeds: (_, payload) => new Promise((resolve, reject) => {
    scheduleRequests.updateStaffingNeeds(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchStaffingNeedsShiftsFulfillment: (_, month) => new Promise((resolve, reject) => {
    scheduleRequests.getShiftsFulfillment(month)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  addShifts: (_, payload) => new Promise((resolve, reject) => {
    scheduleRequests.addShifts(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  deleteShift: (_, id) => new Promise((resolve, reject) => {
    scheduleRequests.deleteShift(id)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  fetchMyScheduleForMonth: ({ commit }, month) => new Promise((resolve, reject) => {
    scheduleRequests.getMySchedule(month)
      .then(response => {
        commit('SET_MY_SCHEDULE', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  generateSchedule: (_, payload) => new Promise((resolve, reject) => {
    const query = {
      override: payload.override,
    }
    scheduleRequests.generateSchedule(payload.dateFrom, payload.dateTo, query)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
