import login from '@/http/requests/auth/login/index'

export default {
  login: ({ commit }, payload) => new Promise((resolve, reject) => {
    login.login(payload.userDetails.username, payload.userDetails.password)
      .then(response => {
        commit('SET_2FA_IN_PROGRESS', !response.data.twoFactorComplete)
        if (response.data.token) {
          commit('SET_TOKEN', response.data)
        }
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  twoFactorCheck: ({ commit }, payload) => new Promise((resolve, reject) => {
    login.twoFactorCheck(payload)
      .then(response => {
        if (response.data.token) {
          commit('SET_TOKEN', response.data)
        }
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  logout: ({ commit }) => new Promise(resolve => {
    commit('REMOVE_USER_INFO_AND_TEAM')
    commit('REMOVE_USER_TOKEN')

    if ('caches' in window) {
      caches.keys()
        .then(names => {
          for (let i = 0; i < names.length; i += 1) {
            const name = names[i]
            caches.delete(name)
            // console.info(`Cache "${name}" deleted.`)
          }
        })
        .catch(error => {
          console.error('Error accessing cache:', error)
        })
    }

    setTimeout(() => {
      window.location.reload(true)
    }, 250)
    resolve(true)
  }),
  fetchUserInfo: ({ commit }) => new Promise((resolve, reject) => {
    login.userInfo()
      .then(res => {
        commit('SET_USER_INFO', res.data)
        resolve(res)
      })
      .catch(err => reject(err))
  }),
  setUserInfo: ({ commit }, data) => new Promise(() => {
    commit('SET_USER_INFO', data)
  }),
  setShowInitTeamPopup: ({ commit }, data) => new Promise(() => {
    commit('SET_SHOW_INIT_TEAM_POPUP', data)
  }),
  updateUserTeamStatus: ({ commit }, payload) => new Promise((resolve, reject) => {
    commit('SET_USER_TEAM_STATUS', payload.status)
    login.changeStatus(payload.teamId, payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  clearUserTeam: ({ commit }) => new Promise(() => {
    commit('CLEAR_USER_TEAM', {})
  }),
  fetchUserTeam: ({ commit }) => new Promise((resolve, reject) => {
    login.userCurrentTeam()
      .then(res => {
        commit('SET_USER_TEAM', res.data)
        resolve(res)
      })
      .catch(err => reject(err))
  }),
  fetchNotifications: ({ commit }) => new Promise((resolve, reject) => {
    try {
      login.userNotifications()
        .then(response => {
          commit('SET_NOTIFICATIONS', response.data)
          resolve(response)
        })
    } catch (error) {
      reject(error)
    }
  }),
  markNotificationAsRead: (_, id) => new Promise((resolve, reject) => {
    login.markNotificationAsRead(id)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchAccessTokenByRefreshToken: ({ commit }) => new Promise((resolve, reject) => {
    login.refreshToken()
      .then(response => {
        commit('SET_TOKEN', response.data)
        resolve(response)
      })
      .catch(err => reject(err))
  }),
}
